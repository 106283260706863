@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  /* background-color: #cac8bd; */
  /* background: linear-gradient( #cbc8b794, #b5b4ac ); */
  background: linear-gradient( #cbc8b794, #b0afa7 );
  background-attachment: fixed;
}

.datePicker #date {
  padding-left: 2rem;
}

.login-div {
  background: rgb(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  border-left: 1px solid rgba(100, 94, 94, 0.25);
  border-radius: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.1); 
}

.country-dropdown {
  border: 1px solid black;
  padding: 0rem .6rem;
  /* padding: .5rem .6rem; */
  height: 2.35rem;
  border-radius: 5px;
  width: 100%;
  background-color: white;
}


/* REACT DATEPICKER STYLES */

.react-datepicker {
}
.custom-datepicker {
  transform: scale(1.3); /* Adjust the scale factor as needed */
  /* transform-origin: top center; */
  border-radius: 8px !important;
  border: 1px solid black !important;
}

.react-datepicker__month-container {
  padding-bottom: .8rem;
  font-weight: 500 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  font-size: .79rem !important;
}

.react-datepicker__current-month {
  font-size: .81rem !important;
  font-weight: 600 !important;
  /* color: var(--dark) !important; */
  padding-top: .1rem !important;
  padding-bottom: .3rem !important;
}

.react-datepicker__header {
  font-size: .69rem;
  font-weight: 600 !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.68) !important;
  /* background: white !important; */
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next
{
  padding-top: 1rem !important;
}

@media (max-width: 970px) {
  .custom-datepicker {
    max-width: 17rem;
  }
  .react-datepicker {
    top: 0;
    overflow: scroll;
    transform: translateY(-5rem) scale(1.25);
    max-height: 60vh;
  }
  .react-datepicker__month-container {
    overflow: auto;
    width: 100%;
  }
}



#paypal-card-container {
  height: 87px;
  padding: 0rem;
  
}
.card-field-number {
  padding: 0rem;
  background-color: red;
  border: 2px solid green;
}
.card-field {
  padding: 0rem;
  height: 10rem;
  border: 1px solid black;
}

#card-number-field-container,
#card-expiry-field-container {
  height: 3.4rem;
}

.highlighted {
  background-color: #7f8aff; /* Set the background color to a light blue */
  color: white; /* Set the text color to white */
  stroke: white; /* Set the stroke color to white for SVG elements */
  border-color: white;
}
